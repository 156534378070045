import logo from './logohor.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div className="warpimage">
              <img src={logo} className="App-logo" alt="logo"/>
          </div>
          <p>
              Aktualnie prowadzimy prace nad stroną. Dziękujemy za cierpliwość i zapraszamy do odwiedzenia nas ponownie wkrótce!
        </p>
      </header>
    </div>
  );
}

export default App;
